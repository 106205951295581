*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}
body{
    text-align: center;
    background-color: #1b2034;
    color: #fff;
}


.app{
    padding: 2rem 1rem;
}
.heading{
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 2rem;
    flex-direction: row;
    align-content: center;
}

.text-input{
    margin:  0 1rem ;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.logo{
    margin: 0 2rem;
    width: 50px;
    height: 50px;
    filter: invert(100%);
}

span{
    font-size: 2.5rem;
    text-align: center;
}

input{
    appearance: none;
    outline: none;
    border:none;
    background: #eee;
    width:100%;
    max-width: 300px;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin-right: 1rem;
}

button, a{
    appearance: none;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    color: #2bcb4b;
    font-size: 1.5rem;
    line-height: 1;
    text-decoration: none;
}

img {
    display: block;
    width: 100%;
    max-width: 400px;
    margin: 2rem auto;
}

@media screen and (max-width:480px) {
    span{
        font-size: 1.5rem;
    }
    .input{
        margin: 0 
    }
    .heading{
        margin: 0;
    }
    .text-input{
        margin: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .logo {
        width: 40px;
        height: 40px;
        color: #fff;
        margin: 0 0.5rem;
    }
    img{
        width: 300px;
    }
    button{
        margin: 1rem 0;
    }
}